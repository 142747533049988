import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSwitch.figmaUrl.android} codeUrl={checklists.componentSwitch.codeUrl.android} checklists={checklists.componentSwitch.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Switch is component that allows the user to change a setting between two states. Switch usually used for settings, where the user can change the status setting to on or off. Switch is an alternative to the toggle button so that the android apps look more attractive.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/android-1.png",
            "alt": "switch android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`There are 2 variants of Switch that we can use in Legion :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Primary Switch. To implement the component, we use this tag `}<inlineCode parentName="p">{`LgnPrimarySwitch`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Secondary Switch. To implement the component, we use this tag `}<inlineCode parentName="p">{`LgnSecondarySwitch`}</inlineCode></p>
      </li>
    </ol>
    <p>{`The following is usage to implement Switch in XML`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.switch.LgnPrimarySwitch
   android:layout_width="match_parent"
   android:layout_height="match_parent"
   android:layout_marginHorizontal="@dimen/dimen_16dp"
   android:layout_marginTop="@dimen/dimen_16dp"
   android:checked="true"
   android:text="Primary Switch"/>

<com.telkom.legion.component.switch.LgnSecondarySwitch
   android:layout_width="match_parent"
   android:layout_height="match_parent"
   android:layout_marginHorizontal="@dimen/dimen_16dp"
   android:layout_marginTop="@dimen/dimen_16dp"
   android:checked="true"
   android:text="Secondary Switch"/>
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`There are 2 variants of Legion Switch :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Primary Switch`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Secondary Switch`}</p>
      </li>
    </ol>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/android-1.png",
            "alt": "switch variant"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Text value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isEnable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable switch directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checked Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:checked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isChecked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set checked status switch directly via xml`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example`}</h2>
    <p>{`The following is example usage for switch implementation`}</p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.switch.LgnPrimarySwitch
    android:layout_width="match_parent"
    android:layout_height="match_parent"
    android:layout_marginHorizontal="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    android:checked="true"
    android:text="Primary Switch"/>

<com.telkom.legion.component.switch.LgnSecondarySwitch
    android:layout_width="match_parent"
    android:layout_height="match_parent"
    android:layout_marginHorizontal="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    android:checked="true"
    android:text="Secondary Switch"/>

<com.telkom.legion.component.switch.LgnPrimarySwitch
    android:layout_width="match_parent"
    android:layout_height="match_parent"
    android:layout_marginHorizontal="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    android:checked="true"
    android:enabled="false"
    android:text="Primary switch"/>
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
switchPry1.setOnCheckedChangeListener { view,isChecked ->
                if(isChecked){
                    toast("Switch Legion Primary Checked")
                }else{
                    toast("Switch Legion Primary Unchecked");
                }
            }

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center",
        "width": 250,
        "height": 500
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switch_preview_2.gif",
            "alt": "switch preview"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      